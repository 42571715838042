/*====================
    Breadcrump Area 
======================*/

.rbt-breadcrumb-default {
    .title {
        color: var(--color-heading);
        margin-bottom: 5px;
        font-size: 50px;

        @media #{$lg-layout} {
            font-size: 40px;
        }

        @media #{$md-layout} {
            font-size: 40px;
        }

        @media #{$sm-layout} {
            font-size: 28px;
        }
    }
}


/*-----------------------------
    Breadcrumd Page List  
-------------------------------*/
.page-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        display: inline-block;
        font-size: 14px;
        color: var(--color-body);
        margin: 0;

        i {
            display: inline-block;
            position: relative;
            top: 2px;
        }

        &.active {
            color: var(--color-body);
            opacity: 0.6;
        }

        a {
            color: var(--color-body);
            transition: 0.5s;
        }

        a:hover {
            color: var(--color-primary);
        }

        .icon-right {
            padding: 0 4px;

            i {
                color: var(--color-body);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}


/*----------------------------
    Breadcrumb Style Two  
------------------------------*/
.breadcrumb-style-max-width {
    max-width: 1085px;
    margin-left: auto;
    margin-right: auto;

    @media #{$lg-layout} {
        max-width: 960px;
    }

    @media #{$md-layout} {
        max-width: 720px;
    }

    @media #{$sm-layout} {
        max-width: 540px;
    }

    @media #{$large-mobile} {
        max-width: 420px;
    }

    @media #{$small-mobile} {
        max-width: inherit;
    }
}


.rbt-article-content-wrapper {
    padding: 50px 100px;
    background: #fff;
    box-shadow: var(--shadow-2);
    padding-top: 0;
    overflow: hidden;

    @media #{$md-layout} {
        padding: 50px 30px;
        padding-top: 0;
    }

    @media #{$sm-layout} {
        padding: 50px 30px;
        padding-top: 0;
    }
}

.rbt-overlay-page-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
    box-shadow: var(--shadow-2);

    .breadcrumb-image-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 44px 140px 50px;
        background: #fff;
        border-radius: 4px 4px 0 0;
        margin: 120px auto 0;

        @media #{$md-layout} {
            padding: 44px 30px 50px;
        }

        @media #{$sm-layout} {
            padding: 44px 30px 50px;
            border-radius: 0;
        }

        @media #{$small-mobile} {
            margin: 0px auto 0;
        }

        .breadcrumb-image-wrapper {
            display: block;
            position: absolute;
            top: -243px;
            bottom: -250px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
            height: -moz-calc(100% + 243px);
            height: calc(100% + 243px);
            width: 100vw;
            min-width: 1024px;
            z-index: -4;

            img {
                position: absolute;
                inset: 0px;
                box-sizing: border-box;
                padding: 0px;
                border: none;
                margin: auto;
                display: block;
                width: 0px;
                height: 0px;
                min-width: 100%;
                max-width: 100%;
                min-height: 100%;
                max-height: 100%;
            }
        }
    }

    .breadcrumb-content-top {
        .title {
            font-size: 42px;

            @media #{$sm-layout} {
                font-size: 32px;
            }
        }
    }
}

/*----------------------------
    Breadcrumb Style Main  
------------------------------*/

.rbt-page-banner-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    padding: 60px 0px 235px;
    width: 100%;
    overflow: hidden;

    @media #{$sm-layout} {
        padding: 40px 0px 180px;
    }

    .rbt-banner-image {
        @extend %bg-gradient-9;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -4;

        img {
            position: absolute;
            inset: 0px;
            box-sizing: border-box;
            padding: 0px;
            border: none;
            margin: auto;
            display: block;
            width: 0px;
            height: 0px;
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
        }
    }

    //d-flex justify-content-start align-items-center mt--20 mb--20

    .rbt-banner-content-top {
        .title-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 20px;

            @media #{$large-mobile} {
                display: block;
            }

            .rbt-badge-2 {
                margin-left: 20px;

                @media #{$large-mobile} {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }
        }

        .title {
            font-size: 42px;

            @media #{$sm-layout} {
                font-size: 32px;
            }
        }

        .description {
            color: var(--color-heading);
        }
    }

}

.rbt-section-overlayping-top {
    margin: -175px auto 0;
    position: relative;
    z-index: 2;
}


/*-----------------------------
    Breadcrumb Style Three  
------------------------------*/
.rbt-breadcrumb-default {
    &.rbt-breadcrumb-style-3 {
        position: relative;
        min-height: 490px;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding: 80px 0;

        @media #{$md-layout} {
            height: auto;
            padding: 120px 0;
        }

        @media #{$sm-layout} {
            height: auto;
            padding: 60px 0;
        }

        .breadcrumb-inner {
            >img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        .content {
            position: relative;
            z-index: 2;
            padding-right: 10%;
        }
    }
}



.rbt-breadcrumb-style-3 {
    .title {
        margin-bottom: 20px;
    }

    .description {
        color: var(--color-heading);

        @media #{$sm-layout} {
            margin-bottom: 20px;
        }
    }

    .page-list {
        margin-bottom: 20px;
    }

    .rating {
        font-size: 14px;
        font-weight: 500;
    }

    .total-student {
        font-size: 14px;
        font-weight: 500;

        span {
            color: var(--color-heading);
        }
    }

    .rbt-meta {
        li {
            color: var(--color-heading);
        }
    }

    .rbt-course-details-feature {
        margin: -5px;

        .feature-sin {
            margin: 5px;
            display: flex;
            gap: 5px;
        }
    }
}