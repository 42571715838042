/*---------------------------
    Unit Test Blog Details  
-----------------------------*/
.wp-block-quote,
blockquote,
.wp-block-coblocks-click-to-tweet,
.wp-block-pullquote blockquote,
.wp-block-quote.is-style-large,
.wp-block-quote.is-large,
blockquote.has-text-align-right,
.wp-block-quote.has-text-align-right {
    padding: 40px 40px 40px 100px;
    position: relative;
    font-size: 24px;
    line-height: 1.4;
    background: transparent;
    border-radius: 5px;
    margin: 30px 0;
    font-weight: 500;
    color: var(--color-heading);
    z-index: 1;
    border: 1px solid var(--color-border);

    @media #{$md-layout} {
        padding: 20px;
        padding-left: 40px;
    }

    @media #{$sm-layout} {
        padding: 20px;
        padding-left: 40px;
    }

    @media #{$sm-layout} {
        margin: 20px 0;
    }

    &::before {
        position: absolute;
        content: "";
        top: 40px;
        left: 30px;
        z-index: -1;
        background-image: url(../../images/shape/quote.svg);
        background-repeat: no-repeat, repeat;
        width: 52px;
        height: 44px;
        transform: rotate(180deg);
        filter: grayscale(1);

        @media #{$md-layout} {
            top: 7px;
            left: 0;
            font-size: 48px;
        }

        @media #{$sm-layout} {
            top: 7px;
            left: 0;
            font-size: 48px;
        }
    }

    p {
        margin-bottom: 0;
        font-style: normal;
        font-size: 24px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--color-heading);
    }

    cite {
        margin-top: 10px;
    }
}